import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/about-us.js";
import Home from "./pages/home.js";
import TopNavbar from "./components/Nav/TopNavbar.jsx";
import ContactUs from "./pages/contact.js";
import Services from "./pages/services.js";
import Career from "./pages/career.js";
import ScrollToTop from "./components/ScrollToTop.js";
import NotFound from "./pages/404.js";
import Portfolio from "./pages/portfolio.js";
import ProjectDetailPage from "./components/Portfolio/portfolioDetail.js";
import Footer from "./components/Sections/Footer.jsx";


export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Router>
      <TopNavbar />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/career" element = {<Career/>}/>
        <Route path="/portfolio" element = {<Portfolio/>}/>
        <Route path="/portfolio/detail/:id" element = {<ProjectDetailPage/>}/>
        <Route path="/*" element = {<NotFound/>}/>
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

