import React from "react";
import Careers from "../components/Career/Careers";
import CareerList from "../components/Career/CareerList";
import Internships from "../components/Career/Internships";
import HowToApply from "../components/Career/HowToApply";
import LifeAtSmoothSync from "../components/Career/LifeAtSmoothSync";
import { Helmet } from "react-helmet";

const career = () => {
  return (
    <>
      <Helmet>
        <title>Careers | Join Our Team at Smoothsync Innovations</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at Smoothsync Innovations. Discover open roles, internships, and how to apply. Join our dynamic team and grow your career in the tech industry."
        />
        <meta
          name="keywords"
          content="careers at Smoothsync Innovations, job opportunities, internships, how to apply, work culture, life at Smoothsync"
        />
        <meta name="author" content="Smoothsync Innovations" />
        <meta name="robots" content="index, follow" />
      
      </Helmet>
      <Careers />
      <CareerList />
      <Internships />
      <LifeAtSmoothSync />
      <HowToApply />
    </>
  );
};

export default career;
