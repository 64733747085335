import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import "./ValueSection.css";

const portfolioArray = [
  {
    title: "Foodie's Choice (Restaurant Website, London)",
    description:
      "Foodie's Choice is a website designed for an Indian cuisine restaurant in London. The site features an interactive menu with high-quality images, detailed descriptions, and price listings. Customers can place reservations online, read and leave reviews, and explore special promotions. The website is optimized for fast performance and a seamless user experience. The admin panel allows restaurant staff to update menu items, manage table bookings, respond to reviews, and track customer analytics to improve service",
    icon: "/fc-website.png",
    technologies: "React, Node.js, Postgresql, Express.js, Tailwind CSS",
    client: "Viren Gajjar",
    client_review: `"Foodie's Choice needed a sleek, user-friendly website, and SmoothSync delivered beyond expectations. The admin panel makes it so easy to manage reservations and menu updates!"`,
    link: "https://www.foodiechoice.co.uk/",
    status: "Completed",
    id: 1,
  },

  {
    title: "Astha Beauty & Care (Salon & Spa Website)",
    description:
      "Astha Beauty & Care is a modern website designed for a beauty salon, providing a seamless booking experience for customers. The site includes a full catalog of services such as hair treatments, skincare, spa packages, and bridal makeup. Users can browse pricing, view expert beautician profiles, and book appointments online. The website also features a gallery showcasing transformations, a blog section for beauty tips, and customer reviews to build credibility. The admin panel allows salon managers to manage appointments, update service details, handle promotional offers, and track customer interactions, ensuring efficient business operations.",
    icon: "/asthacare-website.png",
    status: "Completed",
    client: "Ruchita Suthar",
    client_review: `"SmoothSync created a seamless and stylish website for my salon. Our appointment bookings have doubled, and clients love the online booking feature! Highly recommended!"`,
    link: "https://www.asthabeautycare.in.net/",
    technologies: "React, Bootstrap 5",
    id: 2,
  },
  {
    title: "Proland Deals (Real Estate Platform)",
    description:
      " Proland Deals is a fully functional real estate platform designed to connect property buyers and sellers. It features a powerful property listing system, advanced search filters, and integrated payment options. Sellers can track inquiries and views, while buyers can save favorites and contact sellers. The platform also includes an admin panel to manage listings, users, and analytics.",
    icon: "/proland-website.png",
    client: "Apurva Bhavsar",
    link: "https://proland-website.vercel.app/",
    technologies: "Node.js, PostgreSQL, Express.js React, AWS S3",
    status: "Ongoing",
    id: 3,
  },

  {
    title: "Fiesta Flavors (Mexican Restaurant, Canada)",
    description:
      "Fiesta Flavors is a vibrant and engaging restaurant website tailored for a Mexican eatery in Canada. It includes an interactive menu where customers can filter items based on dietary preferences, view daily specials, and place advance reservations. Users can also subscribe to a newsletter for exclusive deals. The admin panel empowers restaurant staff to manage promotions, update seasonal dishes, and track customer engagement metrics, ensuring a smooth digital experience for both users and administrators.",
    icon: "/ff-website.png",
    technologies: "Node.js, React, PostgreSQL, Express.js, AWS S3",
    client: "Dhiren Prajapati",
    link: "https://ff-website-ca.vercel.app/",
    status: "Ongoing",
    id: 4,
  },
  {
    title: "Heart Hospital Website",
    description:
      "This healthcare platform serves as the official website for a heart hospital, offering detailed information about treatments, specialists, and patient success stories. The website includes a secure and user-friendly appointment booking system, allowing patients to select doctors, view available time slots, and receive confirmation notifications. A career portal lets healthcare professionals apply for job openings. The admin panel gives hospital administrators full control over doctor availability, appointment scheduling, patient inquiries, and content management, ensuring a smooth workflow.",
    icon: "/nadiadheart-website.png",
    client: "Dr. Dignesh Vasava",
    link: "https://nadiad-heart-hospital-website.vercel.app/",
    technologies: "Node.js, React, PostgreSQL, Express.js, AWS S3",
    status: "Ongoing",
    id: 5,
  },
];

const buttonStyle = {
  display: "inline-block",

  padding: "12px 24px",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#fff",
  backgroundColor: "#007bff",
  textDecoration: "none",
  borderRadius: "8px",
  transition: "all 0.3s ease-in-out",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  border: "none",
};

const hoverStyle = {
  backgroundColor: "#0056b3",
  transform: "scale(1.05)",
  boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
};

const ProjectDetailPage = () => {
  const { id } = useParams();
  console.log("🚀 ~ ProjectDetailPage ~ id:", id);

  const DetailData = portfolioArray?.find((res) => res?.id === Number(id));
  console.log("🚀 ~ ProjectDetailPage ~ DetailData:", DetailData);

  return (
    <Wrapper>
      <div className="lightBg">
        <div className="container">
          <SectionTitle>{DetailData?.title}</SectionTitle>

          <SectionDescription>{DetailData?.description}</SectionDescription>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            <h1 className="Value-Section-title"></h1>
            <div className="value-container">
              <ul className="feature-list">
                <li className="feature-item">
                  <span className="feature-title">Technologies</span>:{" "}
                  {DetailData?.technologies}
                </li>
                <li className="feature-item">
                  <span className="feature-title">Client</span>:{" "}
                  {DetailData?.client}
                </li>
                <a
                  href={DetailData?.link} // Replace with your actual live demo link
                  target="_blank"
                  rel="noopener noreferrer"
                  style={buttonStyle}
                  onMouseOver={(e) => Object.assign(e.target.style, hoverStyle)}
                  onMouseOut={(e) => Object.assign(e.target.style, buttonStyle)}
                >
                  View Live Demo
                </a>
              </ul>
              <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex valueimg">
                <img
                  src={DetailData?.icon}
                  alt="office"
                  className="responsiveImg"
                />
              </ImageWrapper>
            </div>

            <div></div>
          </div>

          {/* Testimonials Section */}
          {DetailData?.client_review && (
            <TestimonialSection>
              <h2>What Our Clients Say</h2>
              <TestimonialCard>
                <p>
                  {DetailData?.client_review}
                </p>
                <span>- {DetailData?.client}</span>
              </TestimonialCard>
            </TestimonialSection>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default ProjectDetailPage;

const Wrapper = styled.section`
  width: 100%;
  padding-top: 140px;
`;

const SectionTitle = styled.h1`
  text-align: center;
  font-size: 2rem;
  color: #1e3a8a;
  margin-bottom: 20px;
  padding-top: 20px;
`;

const SectionDescription = styled.p`
.careers-content p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  line-height: 1.6;
  padding-top: 0.9rem;
  text-align: justify;
  `;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 90%;
    height: auto;
    max-width: 700px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 100%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 90%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;

const TestimonialSection = styled.section`
  padding: 50px 20px;
  background: #f1f5f9;
  text-align: center;
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`;

const TestimonialCard = styled.div`
  background: white;
  padding: 20px;
  margin: 0 auto;
  width: 60%;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 100%;
  }
  p {
    font-style: italic;
  }
  span {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
`;
