import React from "react";
// Sections
import ServicesBar from "../components/Sections/ServicesBar";
import WhyChooseUs from "../components/About/whyChooseus";
import { Helmet } from "react-helmet";

export default function Services() {
  return (
    <>
     <Helmet>
        <title>Our Services | Smoothsync Innovations</title>
        <meta
          name="description"
          content="Explore the range of services offered by Smoothsync Innovations, including web development, software solutions, custom ERP systems, and more. Discover how we can help your business grow."
        />
        <meta
          name="keywords"
          content="Smoothsync Innovations services, web development, software solutions, custom ERP, IT services, business solutions"
        />
        <meta name="author" content="Smoothsync Innovations" />
        <meta name="robots" content="index, follow" />
       
       
      </Helmet>

      <ServicesBar />
      <WhyChooseUs/>
    </>
  );
}


