import React from "react";
import "./Portfolio.css";
import aboutimg from "../../assets/img/project-portfolio.svg";
import styled from "styled-components";

const HeroSectionPortfolio = () => {
  return (
    <div className="container careers-container">
      <div className="careers-content">
        <h1>
          Our <span style={{ color: "blue" }}>Portfolio</span>
        </h1>
        <p>
          We create powerful digital solutions for businesses. Explore our work
          and see how we transform ideas into reality.
        </p>
      </div>
      <ImageWrapper className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
        <img src={aboutimg} alt="office" className="responsiveImg" />
      </ImageWrapper>
    </div>
  );
};

export default HeroSectionPortfolio;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 860px) {
    justify-content: center;
  }
  img.responsiveImg {
    width: 100%;
    height: auto;
    max-width: 500px;
    border-radius: 6px;
    @media (max-width: 768px) {
      max-width: 80%;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
    @media (max-width: 320px) {
      max-width: 100%;
    }
  }
`;
