import React from "react";
import Header from "../components/About/Header";
import Mission from "../components/About/Mission";
import ValueSection from "../components/About/ValueSection";
import { Profilegrid } from "../components/About/ProfileCard";
import WhyChooseUs from "../components/About/whyChooseus";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Smoothsync Innovations</title>
        <meta
          name="description"
          content="Learn more about Smoothsync Innovations, our mission, core values, and the passionate team behind our success. Discover why we're a trusted partner for innovative solutions."
        />
        <meta
          name="keywords"
          content="About Smoothsync Innovations, mission, core values, team, innovative solutions, software development, web development"
        />
        <meta name="author" content="Smoothsync Innovations" />
        <meta name="robots" content="index, follow" />
       
      </Helmet>

      <Header />
      <Mission />
      <ValueSection />
      <Profilegrid />
      <WhyChooseUs />
    </>
  );
};

export default About;
