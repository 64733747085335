import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const portfolioArray = [
  {
    title: "Foodie's Choice (Restaurant Website, London)",
    description:
      "A restaurant website with menu display, online reservations, and customer reviews.",
    icon: "/fc.png",
    status: "Completed",
    id: 1,
  },

  {
    title: "Astha Beauty & Care (Salon & Spa Website)",
    description:
      "A beauty salon website offering online booking, service catalog, and customer testimonials.",
    icon: "/asthacrop.png",
    status: "Completed",
    id: 2,
  },
  {
    title: "Proland Deals (Real Estate Platform)",
    description:
      "A real estate marketplace where property sellers can list properties and buyers can explore and connect with sellers.",
    icon: "/proland-deals2.png",
    status: "Ongoing",
    id: 3,
  },

  {
    title: "Fiesta Flavors (Mexican Restaurant, Canada)",
    description:
      "A Mexican restaurant website featuring an interactive menu and online booking.",
    icon: "/ff.png",
    status: "Ongoing",
    id: 4,
  },
  {
    title: "Heart Hospital Website",
    description:
      "A healthcare website for a heart hospital featuring appointment booking and success stories.",
    icon: "/nadiadheart.png",
    status: "Ongoing",
    id: 5,
  },
];

const AppDevelopmentCard = ({ data }) => {
  let navigate = useNavigate();
  return (
    <Card>
      <ImageWrapper>
        <img src={data?.icon} alt={data?.title} />
      </ImageWrapper>
      <CardContent>
        <StatusBadge status={data.status}>{data.status}</StatusBadge>
        <h3>{data.title}</h3>
        <p>{data.description}</p>
        <MoreDetailsButton
          onClick={() => navigate(`/portfolio/detail/${data?.id}`)}
        >
          More Details
        </MoreDetailsButton>
      </CardContent>
    </Card>
  );
};

export default function PortfolioBar() {
  return (
    <Wrapper>
      <SectionTitle>Our Work</SectionTitle>

      <GridContainer>
        {portfolioArray.map((item, index) => (
          <AppDevelopmentCard key={index} data={item} />
        ))}
      </GridContainer>
    </Wrapper>
  );
}

/* Styled Components */
const Wrapper = styled.section`
  width: 100%;
  padding: 40px 20px;
  background: #f8f9fa;
`;

const SectionTitle = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  color: #1e3a8a;
  margin-bottom: 20px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  background: #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80%;
    height: auto;
    max-height: 180px;
    object-fit: contain;
  }
`;

const CardContent = styled.div`
  padding: 20px;
  text-align: center;
  h3 {
    font-size: 1.3rem;
    color: #1e3a8a;
    margin-bottom: 10px;
  }
  p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
  }
`;

const StatusBadge = styled.div`
  display: inline-block;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
  background: ${(props) =>
    props.status === "Ongoing" ? "#f59e0b" : "#10b981"};
  margin-bottom: 15px;
`;

const MoreDetailsButton = styled.button`
  background: #1e3a8a;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: #3b82f6;
  }
`;
