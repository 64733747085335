import React from "react";
// Sections

import { Helmet } from "react-helmet";
import PortfolioBar from "../components/Portfolio/PortfolioBar";
import HeroSectionPortfolio from "../components/Portfolio/HeroSection";

export default function Portfolio() {
  return (
    <>
      <Helmet>
        <title>Our Work | Portfolio - Smoothsync Innovations</title>
        <meta
          name="description"
          content="Explore our portfolio at Smoothsync Innovations, showcasing our expertise in web development, software solutions, custom ERP systems, UI/UX design, and cutting-edge IT services."
        />
        <meta
          name="keywords"
          content="Smoothsync Innovations Portfolio, web development projects, software solutions, UI/UX design, custom ERP, IT solutions, business technology"
        />
        <meta name="author" content="Smoothsync Innovations" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <HeroSectionPortfolio />
      <PortfolioBar />
    </>
  );
}
