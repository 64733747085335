import React from "react";
import { Link } from "react-router-dom";
import LogoImg1 from "../../assets/img/2.png";
import styled from "styled-components";
import * as Icon from "react-feather";

const ContactsWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

const ContactText = styled.div`
  color: black;
  font-size: 15px;
`;

const SocialsWrapper = styled.div`
  display: flex;
  justify-content: center;
  display: flex;
  height: 35px;
  justify-content: center;
  cursor: pointer;
`;

const SocialIconWrapper = styled.a`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: blue;
    color: white;
  }
`;

const CompanySection = styled.div`
  margin-left: 10rem;
  margin-right: 10rem;

  @media (max-width: 1024px) {
    margin-left: 5rem;
    margin-right: 5rem; // Adjust margin for tablets and smaller screens
  }

  @media (max-width: 768px) {
    margin-left: 3rem;
    margin-right: 3rem; // Further reduce margin for tablets
  }

  @media (max-width: 480px) {
    margin-left: 1rem;
    margin-right: 1rem; // Smaller margin for mobile screens
  }
`;

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section contact-info">
        <h2 className="footer-logo">
          <Link className="pointer" to="/" smooth={true}>
            <img src={LogoImg1} alt="logo" height={100} width={100} />
          </Link>
        </h2>
        <ContactsWrapper href="tel:+918735801527">
          <ContactText>
            {" "}
            <Icon.Phone size={15} color="blue" /> +91 8735801527
          </ContactText>
        </ContactsWrapper>
        <ContactsWrapper href="mailto:info@smoothsyncinnovations.com">
          <ContactText>
            <Icon.Mail size={15} color="blue" /> info@smoothsyncinnovations.com
          </ContactText>
        </ContactsWrapper>
        <ContactsWrapper href="https://maps.app.goo.gl/DwhBFW57RLT2ZD668?g_st=ac">
          <ContactText>
            <Icon.Map size={15} color="blue" /> Amee City Center 304 opposite
            SPU girls hostel Bakrol, Nana Bazaar, Vallabh Vidyanagar Anand,
            Gujarat 388001 India
          </ContactText>
        </ContactsWrapper>
      </div>
      <CompanySection>
        <h3>COMPANY</h3>
        <p>
          {" "}
          <Link to="/about-us" style={{ color: "#000000" }}>
            About Us
          </Link>
        </p>
        <p>
          {" "}
          <Link to="/services" style={{ color: "#000000" }}>
            Services
          </Link>
        </p>
        <p>
          {" "}
          <Link to="/contact-us" style={{ color: "#000000" }}>
            Contact Us
          </Link>
        </p>
        <p>
          {" "}
          <Link to="/career" style={{ color: "#000000" }}>
            Careers
          </Link>
        </p>
      </CompanySection>

      <div className="footer-bottom">
        <SocialsWrapper>
          <SocialIconWrapper href="https://www.instagram.com/smoothsyncinnovations?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
            <Icon.Instagram color="black" size={20} />
          </SocialIconWrapper>
          <SocialIconWrapper href="https://www.linkedin.com/company/smoothsync-innovations">
            <Icon.Linkedin color="black" size={20} />
          </SocialIconWrapper>
        </SocialsWrapper>
        <p>
          © Copyright {new Date().getFullYear()}, All Rights Reserved by
          Smoothsync Innovations
        </p>
      </div>
    </footer>
  );
};

export default Footer;
