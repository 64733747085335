import React, { useState } from "react";
import styled from "styled-components";

const techStack = {
  Frontend: [
    { name: "React.js", image: "/images/react.png" },
    { name: "Next.js", image: "/images/n.png" },
    { name: "HTML", image: "/images/html.png" },
    { name: "CSS", image: "/images/css.png" },
    { name: "Javascript", image: "/images/javascript.png" },
  ],
  Backend: [
    { name: "Node.js", image: "/images/nodejs.png" },
    { name: "Express.js", image: "/images/expressjs.png" },
  ],
  "Mobile App": [
    { name: "React Native", image: "/images/reactnative.png" },
  ],
  "UI/UX": [
    { name: "Figma", image: "/images/figma.png" },
  ],
  Database: [
    { name: "MongoDB", image: "/images/mongodb.png" },
    { name: "PostgreSQL", image: "/images/postgresql.png" },
  ],
  CMS: [
    { name: "WordPress", image: "/images/wordpress.png" },
  ],
  Server: [
    { name: "AWS", image: "/images/aws.png" },
    { name: "Linode", image: "/images/linode.png" },
    { name: "Vercel", image: "/images/vercel.png" },
  ],
  Repositories: [
    { name: "GitHub", image: "/images/github.png" },
  ],
};

const ExpertiseTabs = () => {
  const [activeTab, setActiveTab] = useState("Frontend");

  return (
    <Container>
      <HeaderInfo>
        <h1 className="font40 extraBold">Expertise / Tech Stack</h1>
      </HeaderInfo>
      <TabContainer>
        {Object.keys(techStack).map((category) => (
          <TabButton
            key={category}
            active={activeTab === category}
            onClick={() => setActiveTab(category)}
          >
            {category}
          </TabButton>
        ))}
      </TabContainer>
      <GridContainer>
        {techStack[activeTab].map((tech) => (
          <TechCard key={tech.name}>
            <TechImage src={tech.image} alt={tech.name} />
            <p>{tech.name}</p>
          </TechCard>
        ))}
      </GridContainer>
    </Container>
  );
};

export default ExpertiseTabs;

// Styled Components
const Container = styled.div`
  max-width: 1000px;
  margin: auto;
  padding: 20px;
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-weight: bold;
  }
`;

const TabContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding-bottom: 10px;
`;

const TabButton = styled.button`
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  background: none;
  border-bottom: ${({ active }) => (active ? "4px solid #007BFF" : "none")};
  color: ${({ active }) => (active ? "#007BFF" : "#555")};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #007bff;
  }
`;


const GridContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 520px) {
    flex-direction: column;
    place-items: center;
    align-items: center; // Stack cards vertically on mobile devices
  }
`;

const TechCard = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  text-align: center;

  p {
    margin-top: 10px;
    font-weight: bold;
    font-size: 12px;
  }
`;

const TechImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;
